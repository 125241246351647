import { createContext, FC, ReactNode, useContext } from 'react'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { getDefaultQueryValue } from 'utilities/api.utility'
import { useAddressListQuery } from './address-book.hooks'

const defaultData = getDefaultQueryValue<UploadedAddressesResponse | undefined>()
export const AddressListContext = createContext(defaultData)

export const AddressListProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useAddressListQuery()
  return <AddressListContext.Provider value={value}>{children}</AddressListContext.Provider>
}

export function useAddressList() {
  return useContext(AddressListContext)
}
