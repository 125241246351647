import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { Box, Button, Column, GridContainer, H3, Icon, Row, Typography } from '@vp/swan'
import { useNavigateToReview } from 'hooks/navigate.hooks'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { PageLayout } from 'layouts/page/page.layout'
import { useAddressListQuery, useAddressListUpload } from 'lib/address-list'
import { withErrorBoundary } from 'lib/errors'
import { PageName } from 'lib/telemetry'
import { PageNameForLogging } from 'lib/telemetry/tracking.types'
import { useUploadPageStatus } from 'modules/upload/contexts/upload-status.context'
import { useDownloadTemplate } from 'modules/upload/hooks/useDownloadTemplate'
import { UploadPageStatus } from 'modules/upload/types/upload-status.type'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import layoutClasses from '../../../../layouts/page/page-layout.module.scss'
import { BtnUpload } from '../btn-upload/btn-upload.component'
import { UploadFooter } from '../upload-footer/upload-footer.component'
import { UploadSample } from '../upload-sample/upload-sample.component'
import { UploadStatus } from '../upload-status/upload-status.component'
import { MailingServicesInstructions } from './mailing-services-instructions'
import './upload-container.module.scss'
import classes from './upload-container.module.scss'

function arrLenSum(...arrays: (unknown[] | undefined | null)[]): number {
  return arrays.reduce((sum, array) => sum + (array?.length || 0), 0)
}

const UploadContainerInternal: FC<unknown> = () => {
  const { uploadPageStatus, setUploadPageStatus } = useUploadPageStatus()
  const [file, setFile] = useState<File>()
  const [fileNameNavbar, setFileNameNavbar] = useState<string>()
  const { data: requestId, isLoading: isUploading, isError: isErrorUploading } = useAddressListUpload(file)
  const {
    data: addressList,
    isLoading: isProcessing,
    isError: isErrorProcessing,
    isSuccess: isSuccessProcessing,
  } = useAddressListQuery({ requestId, retryCount: 6 })

  const [showUploadStatus, setShowUploadStatus] = useState(false)
  const [canShowAlert, setCanShowAlert] = useState<boolean>(false)
  const navigateToReview = useNavigateToReview()
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)
  const downloadTemplate = useDownloadTemplate()
  const { logError, logWarn } = useLogger()

  useEffect(() => {
    setShowUploadStatus([UploadPageStatus.UPLOADING, UploadPageStatus.PROCESSING, UploadPageStatus.SUCCESS].includes(uploadPageStatus))

    setCanShowAlert(
      uploadPageStatus !== UploadPageStatus.INITIAL &&
        uploadPageStatus !== UploadPageStatus.UPLOADING &&
        uploadPageStatus !== UploadPageStatus.PROCESSING &&
        uploadPageStatus !== UploadPageStatus.SUCCESS,
    )
  }, [uploadPageStatus])

  useEffect(() => {
    // reset everything on file change
    setUploadPageStatus(UploadPageStatus.INITIAL)
    setCanShowAlert(false)
  }, [file, setUploadPageStatus])

  useEffect(() => {
    if (isUploading) {
      setUploadPageStatus(UploadPageStatus.UPLOADING)
    } else if (isErrorUploading) {
      setUploadPageStatus(UploadPageStatus.ERROR_UPLOADING_FAILED)
      logError('Error while uploading the file', { contextData: { requestId, status: UploadPageStatus.ERROR_UPLOADING_FAILED } })
    } else if (isProcessing) {
      setUploadPageStatus(UploadPageStatus.PROCESSING)
    } else if (isErrorProcessing) {
      setUploadPageStatus(UploadPageStatus.ERROR_PROCESSING_FAILED)
      logError('Error while processing the uploaded file', { contextData: { requestId, status: UploadPageStatus.ERROR_PROCESSING_FAILED } })
    } else if (isSuccessProcessing) {
      setUploadPageStatus(UploadPageStatus.SUCCESS)
    }
  }, [isUploading, isErrorUploading, isProcessing, isErrorProcessing, isSuccessProcessing, setUploadPageStatus, requestId, logError])

  useEffect(() => {
    if (
      requestId &&
      isSuccessProcessing &&
      uploadPageStatus !== UploadPageStatus.ERROR_INVALID_FILE_NAME &&
      uploadPageStatus !== UploadPageStatus.ERROR_INVALID_FILE_TYPE
    ) {
      const totalAddressCount = arrLenSum(addressList?.validAddresses, (addressList?.duplicatedAddressGroups || []).flat(), addressList?.invalidAddresses)
      if (totalAddressCount) {
        navigateToReview(requestId)
      } else {
        setUploadPageStatus(UploadPageStatus.ERROR_EMPTY_FILE)
        logWarn('The uploaded file is empty', { contextData: { requestId, status: UploadPageStatus.ERROR_EMPTY_FILE } })
      }
    }
  }, [addressList, navigateToReview, setUploadPageStatus, requestId, isSuccessProcessing, uploadPageStatus, logWarn])

  return (
    <PageLayout pageName={trackingProductPageName} pageNameForLogging={PageNameForLogging.UPLOAD_PAGE} fileName={fileNameNavbar}>
      {(showUploadStatus || canShowAlert) && <UploadStatus fileNameNavbar={fileNameNavbar} />}
      {!showUploadStatus && (
        <Box className={`${classes.container} ${layoutClasses.fullHeight} ${layoutClasses.minHeight}`}>
          <GridContainer>
            <Row pt={8}>
              <Column span={6} spanSm={12}>
                <GridContainer>
                  <Row>
                    <Column span={10} spanSm={12}>
                      <>
                        <H3 fontSize="x2large" mb={{ md: 1, xs: 1 }} fontWeight="bold">
                          <FormattedMessage defaultMessage="Upload your mailing list." />
                        </H3>
                        <Typography fontSize={'standard'} mb={5}>
                          <MailingServicesInstructions />
                        </Typography>
                        <Typography fontSize={'standard'} mb={1}>
                          <FormattedMessage defaultMessage="More questions? Help is here: 1.855.210.1085" />
                        </Typography>
                        <Typography fontSize={'small'} mb={5} textColor="subtle">
                          <FormattedMessage defaultMessage="Final price based on the quantity." />
                        </Typography>
                      </>
                    </Column>
                  </Row>
                  <Row pt={{ md: 4, xs: 3 }}>
                    <Column span={7} spanMd={8} spanSm={12}>
                      <BtnUpload className={classes.actionBtn} setFile={setFile} setFileNameNavbar={setFileNameNavbar} />
                      <Button
                        iconPosition="right"
                        data-section="Mailing List Page:Link Clicked"
                        data-position="1"
                        data-translation="Download template"
                        specialVariant="design-path"
                        onClick={downloadTemplate}
                        size="standard"
                        className={classes.actionBtn}
                        mt={4}
                      >
                        <Icon className={classes.icon} iconType="download" />
                        <FormattedMessage defaultMessage="Download template" description="Button label to download template for the mailing list" />
                      </Button>
                    </Column>
                  </Row>
                </GridContainer>
              </Column>
              <Column mt={{ md: 0, sm: 8, xs: 6 }} span={6} spanSm={12}>
                <UploadSample />
              </Column>
            </Row>
          </GridContainer>
          <UploadFooter />
        </Box>
      )}
    </PageLayout>
  )
}

export const UploadContainer = withErrorBoundary(UploadContainerInternal)
