import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react'
import { UploadPageStatus } from '../types/upload-status.type'

type UploadStatusProps = { uploadPageStatus: UploadPageStatus; setUploadPageStatus: (val: UploadPageStatus) => void }

const UploadStatusContext = createContext<UploadStatusProps | undefined>(undefined)

export const UploadStatusContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [uploadPageStatus, setUploadPageStatus] = useState<UploadPageStatus>(UploadPageStatus.INITIAL)
  const value = useMemo(() => ({ uploadPageStatus, setUploadPageStatus }), [uploadPageStatus])
  return <UploadStatusContext.Provider value={value}>{children}</UploadStatusContext.Provider>
}

export function useUploadPageStatus(): UploadStatusProps {
  const context = useContext(UploadStatusContext)
  if (context === undefined) {
    throw new Error('useUploadPageStatus should be used only inside UploadStatusContextProvider')
  }
  return context
}
