/* eslint-disable @typescript-eslint/no-floating-promises */
import { localeAndPagePrefixMap } from 'constants/global.constant'
import { QUERY_KEY_STRATEGY } from 'constants/query-key.constants'
import { useNextSearchParams } from 'contexts/query-param.context'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useCallback } from 'react'
import { getLocationPrefix } from 'utilities/navigate.utils'
import { useLocalization } from './use-localization'

function useNavigate() {
  const { locale } = useLocalization()
  const prefix = getLocationPrefix()
  const errorToast = useToastSomethingWentWrong()

  return useCallback(
    (path: string, query: URLSearchParams, replace = false) => {
      let queryString = query.toString()
      if (queryString) queryString = `?${queryString}`
      const url = `${prefix}/${localeAndPagePrefixMap[locale] || ''}/${path}/${queryString}`
      if (typeof window !== 'undefined') {
        if (replace) {
          window.location.replace(url)
        } else {
          window.location.assign(url)
        }
      } else {
        errorToast()
      }
    },
    [locale, prefix, errorToast],
  )
}

export function useNavigateToUpload() {
  const nextParams = useNextSearchParams({})
  const navigate = useNavigate()
  return useCallback(
    (workId?: string) => {
      if (workId) {
        nextParams.set('workId', workId)
      }
      navigate('upload', nextParams)
    },
    [navigate, nextParams],
  )
}

export function useNavigateToReview() {
  const nextParams = useNextSearchParams({})
  const somethingWentWrong = useToastSomethingWentWrong()
  const navigate = useNavigate()
  return useCallback(
    (requestId: string, strategy?: string) => {
      if (!requestId) {
        somethingWentWrong()
        return
      }
      nextParams.set('requestId', requestId)
      if (strategy) {
        nextParams.set(QUERY_KEY_STRATEGY, strategy)
      }
      navigate(`review`, nextParams)
    },
    [navigate, nextParams, somethingWentWrong],
  )
}

export function useNavigateToConfirmation(shouldReplace: boolean, skipMSValidation?: boolean) {
  const nextParams = useNextSearchParams({
    requestId: undefined,
  })
  const navigate = useNavigate()
  return useCallback(
    (mailingListId: string) => {
      nextParams.set('mailingListId', mailingListId)
      if (skipMSValidation) {
        nextParams.set('skipMSValidation', 'true')
      }

      navigate('confirmation', nextParams, shouldReplace)
    },
    [navigate, nextParams, shouldReplace, skipMSValidation],
  )
}
