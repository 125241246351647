import { AxiosResponse } from 'axios'

export const generateAndDownloadTheTemplateFrom = (params: { buffer: BlobPart; fileName: string }) => {
  const { buffer, fileName } = params
  const mlTemplateBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

  const tempUrl = window.URL.createObjectURL(mlTemplateBlob)
  const tempAnchor = document.createElement('a')
  tempAnchor.href = tempUrl
  tempAnchor.download = fileName
  document.body.appendChild(tempAnchor)
  tempAnchor.click()

  window.URL.revokeObjectURL(tempUrl)
  document.body.removeChild(tempAnchor)
}

export const getMLTemplateFileNameFrom = (response: AxiosResponse): string => {
  const regex = /filename=([^;]+)/
  const match = response.headers['content-disposition'].match(regex) as RegExpMatchArray

  if (!match || match.length <= 1) {
    console.error('Cannot extract the mailing list template excel file name!')
  }

  return match[1].trim()
}
