import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { Box, Button, Icon, Spinner } from '@vp/swan'
import classNames from 'classnames'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { useSignIn } from 'hooks/use-sign-in.hook'
import { ErrorBoundary, withErrorBoundary } from 'lib/errors'
import { useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { newRelicAddPageAction, newRelicNoticeError } from 'lib/new-relic'
import { useEventTracking } from 'lib/telemetry'
import { PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useIsSignedIn } from 'lib/user'
import { useUploadPageStatus } from 'modules/upload/contexts/upload-status.context'
import { UploadPageStatus } from 'modules/upload/types/upload-status.type'
import { ChangeEvent, FC, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import classes from './btn-upload.module.scss'

const getFileValidity = (fileName: string) => {
  if (!/\.(xls|xlsx|csv)$/i.test(fileName)) {
    return 'INVALID_FORMAT'
  } else if (!/^[a-z0-9-_ ]+\.(xls|xlsx|csv)$/gi.test(fileName)) {
    return 'INVALID_NAME'
  } else {
    return 'VALID'
  }
}

const BtnUploadInternal: FC<{
  className?: string
  setFile: (file: File) => void
  setFileNameNavbar: (val: string) => void
}> = ({ className = undefined, setFile, setFileNameNavbar }) => {
  const loadingMessage = useMsgModalLoadingText()
  const { setUploadPageStatus } = useUploadPageStatus()
  const inputRef = useRef<HTMLInputElement>(null)
  const isPostcard = usePostcardPRDCheck()
  const fireTracking = useEventTracking()
  const isSignedIn = useIsSignedIn()
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)
  const signIn = useSignIn(trackingProductPageName)
  const { logError } = useLogger()

  const trackingData = {
    category: TrackingCategoryType.CONFIGURE_MAILING,
    label: TrackingLabelType.UPLOAD_MAILING_LIST,
    pageName: trackingProductPageName,
  }

  const onFileChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files && evt.target.files.length) {
      const file = evt.target.files[0]
      setFileNameNavbar(file.name)
      const fileValidity = getFileValidity(file.name)
      if (fileValidity === 'VALID') {
        setUploadPageStatus(UploadPageStatus.INITIAL)
        setFile(file)
        if (inputRef.current && inputRef.current.value) {
          inputRef.current.value = ''
        }
        newRelicAddPageAction(TrackingCategoryType.CONFIGURE_MAILING, trackingData)
      } else {
        const errorStatus = fileValidity === 'INVALID_NAME' ? UploadPageStatus.ERROR_INVALID_FILE_NAME : UploadPageStatus.ERROR_INVALID_FILE_TYPE
        setUploadPageStatus(errorStatus)
        logError('Error while uploading the file', { contextData: { status: errorStatus } })
        newRelicNoticeError(TrackingCategoryType.CONFIGURE_MAILING, { result: 'error while uploading' })
      }
    }
  }

  const onUploadClick = () => {
    inputRef.current?.click()
    fireTracking(TrackingEventType.LINK_CLICKED, trackingData)
  }

  return (
    <ErrorBoundary>
      {isSignedIn === false ? (
        <Button
          data-section="Mailing List Page:Link Clicked"
          data-position="1"
          data-translation="Sign In to upload"
          specialVariant="design-path"
          className={classNames(className)}
          skin="primary"
          onClick={signIn}
        >
          {isPostcard ? (
            <FormattedMessage defaultMessage="Sign in to upload a mailing list" description="Button label to sign in to upload a postcards list" />
          ) : (
            <FormattedMessage defaultMessage="Sign in and upload" description="Button label to sign in to upload a postcards list" />
          )}
        </Button>
      ) : (
        <>
          <Button
            data-section="Mailing List Page:Link Clicked"
            data-position="1"
            data-translation="Upload Mailing List"
            className={classNames(className)}
            skin="primary"
            specialVariant="design-path"
            iconPosition="right"
            onClick={onUploadClick}
            disabled={isSignedIn === null}
            aria-describedby="format-help"
          >
            {isPostcard ? (
              <FormattedMessage defaultMessage="Upload a new mailing list" description="Heading for uploading a new mailing list" />
            ) : (
              <FormattedMessage defaultMessage="Upload a new address list" description="Heading for uploading a new address list" />
            )}

            {isSignedIn === null && (
              <Box className={classes.loader}>
                <Spinner accessibleText={loadingMessage} />
              </Box>
            )}
            {isSignedIn && <Icon className={classes.uploadIcon} iconType="upload" alt="" />}
          </Button>
          <input type="file" ref={inputRef} onChange={onFileChange} className={classes.uploadInput} multiple={false} accept=".xls,.xlsx,.csv" />
        </>
      )}
    </ErrorBoundary>
  )
}

export const BtnUpload = withErrorBoundary(BtnUploadInternal)
