import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { AlertBox, AlertBoxDismissButton, Box, FlexBox, Link, Typography } from '@vp/swan'
import { useMsgAlternateTextUploadProgress } from 'lib/intl/msg-alternate-texts.hooks'
import {
  useMsgErrorEmptyFile,
  useMsgErrorIncorrectColumnHeading,
  useMsgErrorNoSavedList,
  useMsgErrorReadFileFormat,
  useMsgErrorReadFileName,
  useMsgErrorUploadFileFormat,
} from 'lib/intl/msg-errors.hooks'
import { useUploadSampleContext } from 'modules/upload/contexts/upload-sample.context'
import { useUploadPageStatus } from 'modules/upload/contexts/upload-status.context'
import { useDownloadTemplate } from 'modules/upload/hooks/useDownloadTemplate'
import { UploadPageStatus } from 'modules/upload/types/upload-status.type'
import { FC, ReactNode, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import LoadingLogo from './loading-animation.gif'
import classes from './upload-status.module.scss'

const DownloadTemplateLink: FC<{ chunks: string[][] }> = ({ chunks }) => {
  const downloadTemplate = useDownloadTemplate()
  return (
    <a className={classes.alertLink} onClick={downloadTemplate} download>
      {chunks}
    </a>
  )
}

const LaunchMailingListExample: FC<{ chunks: string[][] }> = ({ chunks }) => {
  const { setOpenSample } = useUploadSampleContext()
  return (
    <Link to="/#" darkMode onClick={() => setOpenSample(true)} className={classes.alertLink} as="button">
      {chunks}
    </Link>
  )
}

type ErrorAlertProps = {
  text: string | ReactNode
  isErrorAlertDismissed: boolean
  errorAlertDismissHandler: () => void
}

const ErrorAlert: FC<{ props: ErrorAlertProps }> = ({ props }) => {
  const { text, isErrorAlertDismissed, errorAlertDismissHandler } = props
  return (
    <AlertBox
      skin="error"
      mt={6}
      toast
      className={classes.statusAlertToast}
      dismissed={isErrorAlertDismissed}
      onRequestDismiss={() => {
        errorAlertDismissHandler()
      }}
    >
      <Typography textAlign="center">{text}</Typography>
      <AlertBoxDismissButton accessibleText="Close" />
    </AlertBox>
  )
}

export const UploadStatus: FC<{ fileNameNavbar?: string }> = ({ fileNameNavbar }) => {
  const [showLoader, setShowLoader] = useState(false)
  const [isErrorAlertDismissed, setIsErrorAlertDismissed] = useState<boolean>(false)
  const { uploadPageStatus } = useUploadPageStatus()
  const errorUploadFileFormat = useMsgErrorUploadFileFormat()
  const alternateTextUploadProgress = useMsgAlternateTextUploadProgress()
  const errorReadFileFormat = useMsgErrorReadFileFormat()
  const errorReadFileName = useMsgErrorReadFileName()
  const errorNoSavedList = useMsgErrorNoSavedList()
  const errorProcessingFailed = useMsgErrorIncorrectColumnHeading({
    download: (...chunks) => <DownloadTemplateLink chunks={chunks} />,
    example: (...chunks) => <LaunchMailingListExample chunks={chunks} />,
  })
  const errorEmptyFile = useMsgErrorEmptyFile({
    download: (...chunks) => <DownloadTemplateLink chunks={chunks} />,
    example: (...chunks) => <LaunchMailingListExample chunks={chunks} />,
  })
  const errorAlertDismissHandler = () => {
    setIsErrorAlertDismissed(true)
  }

  const { logError, logWarn } = useLogger()

  useEffect(() => {
    setIsErrorAlertDismissed(false)
  }, [fileNameNavbar])

  useEffect(() => {
    setShowLoader(
      uploadPageStatus === UploadPageStatus.UPLOADING || uploadPageStatus === UploadPageStatus.PROCESSING || uploadPageStatus === UploadPageStatus.SUCCESS,
    )
    if (uploadPageStatus === UploadPageStatus.ERROR_UPLOADING_FAILED) {
      logError('Error while uploading the mailing list', {})
    } else if (uploadPageStatus === UploadPageStatus.ERROR_PROCESSING_FAILED) {
      logError('Error while processing the uploaded mailing list', {})
    } else if (uploadPageStatus === UploadPageStatus.ERROR_INVALID_FILE_TYPE) {
      logWarn('File type invalid for uploaded mailing list', {})
    } else if (uploadPageStatus === UploadPageStatus.ERROR_EMPTY_FILE) {
      logWarn('The uploaded file is empty', {})
    }
  }, [uploadPageStatus, logError, logWarn])

  const shouldDisplayErrorAlert: boolean =
    uploadPageStatus === UploadPageStatus.ERROR_UPLOADING_FAILED ||
    uploadPageStatus === UploadPageStatus.ERROR_PROCESSING_FAILED ||
    uploadPageStatus === UploadPageStatus.ERROR_INVALID_FILE_TYPE ||
    uploadPageStatus === UploadPageStatus.ERROR_INVALID_FILE_NAME ||
    uploadPageStatus === UploadPageStatus.ERROR_EMPTY_FILE ||
    uploadPageStatus === UploadPageStatus.ERROR_NO_MAILING_LIST

  const getErrorAlertText = () => {
    switch (uploadPageStatus) {
      case UploadPageStatus.ERROR_UPLOADING_FAILED:
        return errorUploadFileFormat
      case UploadPageStatus.ERROR_PROCESSING_FAILED:
        return errorProcessingFailed
      case UploadPageStatus.ERROR_INVALID_FILE_TYPE:
        return errorReadFileFormat
      case UploadPageStatus.ERROR_INVALID_FILE_NAME:
        return errorReadFileName
      case UploadPageStatus.ERROR_EMPTY_FILE:
        return errorEmptyFile
      case UploadPageStatus.ERROR_NO_MAILING_LIST:
        return errorNoSavedList
      default:
        return ''
    }
  }

  return (
    <>
      {showLoader && (
        <FlexBox alignItems={'center'} justifyContent={'center'} className={classes.fullHeight}>
          <Box className={classes.fileUploading} py={10}>
            <div className={classes.status}>
              <Box className={classes.statusIcon}>
                <img width={uploadPageStatus === UploadPageStatus.SUCCESS ? 62 : 80} alt={alternateTextUploadProgress} src={LoadingLogo} />
              </Box>
              <Typography mt={{ xs: 5, lg: 6 }} fontSize={'standard'}>
                {(uploadPageStatus === UploadPageStatus.PROCESSING || uploadPageStatus === UploadPageStatus.SUCCESS) && (
                  <FormattedMessage defaultMessage="Checking addresses for deliverability..." description="Status text" values={{ listType: 'mailing' }} />
                )}
                {uploadPageStatus === UploadPageStatus.UPLOADING && (
                  <FormattedMessage defaultMessage="Uploading your mailing list..." description="Status text" />
                )}
              </Typography>
            </div>
          </Box>
        </FlexBox>
      )}
      {shouldDisplayErrorAlert && <ErrorAlert props={{ text: getErrorAlertText(), isErrorAlertDismissed, errorAlertDismissHandler }} />}
    </>
  )
}
