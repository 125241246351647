import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react'

type UploadSampleContextProps = { openSample: boolean; setOpenSample: (val: boolean) => void }

const UploadSampleContext = createContext<UploadSampleContextProps | undefined>(undefined)

export const UploadSampleContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [openSample, setOpenSample] = useState<boolean>(false)
  const value = useMemo(() => ({ openSample, setOpenSample }), [openSample])
  return <UploadSampleContext.Provider value={value}>{children}</UploadSampleContext.Provider>
}

export function useUploadSampleContext(): UploadSampleContextProps {
  const context = useContext(UploadSampleContext)
  if (context === undefined) {
    throw new Error('useUploadSampleContext should be used inside UploadSampleContextProvider')
  }
  return context
}
