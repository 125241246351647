import { useRequestIdQueryParam } from 'contexts/query-param.context'
import { useLocalization } from 'hooks/use-localization'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useAddressBookId, useAuthorizationHeader, useIsSignedIn, useShopperId } from 'lib/user'
import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AddressUploadRequest, MailingListUpdateRequest } from 'types/address-book.types'
import { MailingListCompatibilityRequest } from 'types/mailing-list.types'
import { UpdateAddressRequest } from 'types/upload-mailing-list.types'
import { failedApiPromiseForIncompleteArguments } from 'utilities/api.utility'
import {
  createMailingList,
  downloadMailingList,
  fetchMailingListById,
  fetchMailingLists,
  getAddressList,
  getAddressListInProgress,
  getAddressListUpload,
  resetAddressList,
  updateAddressList,
  updateMailingList,
  upsertAddress,
  validateMailingListPostcard,
} from './address-book.api'
const queryKey = {
  inProgressAddressList: 'address-list-in-progress',
  addressList: 'address-list-incomplete',
  uploadPlaceholder: 'address-list-upload',
  allMailingList: 'address-list-completed-All',
  mailingListForId: 'address-list-completed-for-id',
  downloadMailingList: 'download-mailing-list',
  validateMailingServicesPostcards: 'validate-mailing-services-postcards',
}

export function useAddressListUpload(file?: File) {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const addressBookId = useAddressBookId()
  const { locale } = useLocalization()
  const fileKey = useMemo(() => (file ? Date.now() : null), [file])

  return useQuery(
    [queryKey.uploadPlaceholder, shopperId, addressBookId, fileKey],
    ({ signal }) => {
      if (authorizationHeader && isLoggedIn && file && shopperId && addressBookId) {
        return getAddressListUpload({ authorizationHeader, file, shopperId, addressBookId, signal, locale })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!fileKey && !!authorizationHeader && !!isLoggedIn && !!file && !!shopperId && !!addressBookId,
    },
  )
}

export function useAddressListQuery(opt?: { requestId?: string; retryCount: number }) {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const addressBookId = useAddressBookId()
  const requestIdFromParams = useRequestIdQueryParam()
  const requestId = opt ? opt.requestId : requestIdFromParams
  const retryCount = opt ? opt.retryCount : 0

  return useQuery(
    [queryKey.addressList, requestId, addressBookId],
    async ({ signal }) => {
      if (!!requestId && !!authorizationHeader && isLoggedIn && !!shopperId) {
        if (!addressBookId) {
          return
        }
        const addressListInProgress = await getAddressListInProgress({ authorizationHeader, shopperId, addressBookId, signal })
        if (addressListInProgress === undefined || addressListInProgress?.requestId !== requestId) {
          return failedApiPromiseForIncompleteArguments()
        }
        const addressListRes = await getAddressList({ authorizationHeader, shopperId, requestId, signal })
        if (addressListRes.status === 'created' || addressListRes.status === 'processing') {
          throw new Error('Validation in progress')
        }
        return addressListRes
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!requestId && !!authorizationHeader && !!isLoggedIn && !!shopperId,
      retry: retryCount,
    },
  )
}

export function useAddressListInProgress() {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const addressBookId = useAddressBookId()
  const queryClient = useQueryClient()
  return useQuery(
    [queryKey.inProgressAddressList, shopperId, addressBookId],
    async ({ signal }) => {
      if (authorizationHeader && isLoggedIn && shopperId && addressBookId) {
        return getAddressListInProgress({ authorizationHeader, shopperId, addressBookId: addressBookId, signal })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!authorizationHeader && !!isLoggedIn && !!shopperId && !!addressBookId,
      retry: 0,
      onSuccess: data => {
        if (data !== undefined) {
          queryClient.setQueriesData([queryKey.addressList, data.requestId], data)
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(queryKey.addressList)
      },
    },
  )
}

export function useAddressListReset() {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const queryClient = useQueryClient()
  const isEnabled = !!authorizationHeader && !!shopperId

  const mutationResult = useMutation(
    ({ requestId }: { requestId?: string | null }) => {
      if (!!authorizationHeader && isLoggedIn && !!shopperId && !!requestId) return resetAddressList({ authorizationHeader, shopperId, requestId })
      return failedApiPromiseForIncompleteArguments()
    },
    {
      onSuccess: (data, vars) => {
        queryClient.setQueriesData([queryKey.addressList, vars.requestId], () => data)
      },

      onSettled: async () => {
        // await queryClient.invalidateQueries(queryKey.addressList)
      },
    },
  )
  return useMemo(() => ({ isEnabled, mutationResult }), [mutationResult, isEnabled])
}

export function useAddressListUpdate(disableInvalidation = false, fromAutofix = false) {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const requestId = useRequestIdQueryParam()

  const queryClient = useQueryClient()

  const isEnabled = !!authorizationHeader && isLoggedIn && !!shopperId && !!requestId
  const mutationResult = useMutation(
    ({ request }: { request: UpdateAddressRequest }) => {
      if (!!authorizationHeader && isLoggedIn && !!shopperId && !!requestId && request)
        return updateAddressList({
          authorizationHeader,
          shopperId,
          requestId,
          request,
          fromAutofix,
        })
      return failedApiPromiseForIncompleteArguments()
    },
    {
      onSuccess: async data => {
        queryClient.setQueriesData(queryKey.addressList, data)
        if (!disableInvalidation) {
          await queryClient.invalidateQueries(queryKey.addressList)
        }
        newRelicAddPageAction('Update addresses', { result: 'success' })
      },
    },
  )

  return useMemo(() => ({ isEnabled, mutationResult }), [isEnabled, mutationResult])
}

// Address Entity
export function useAddressUpsert() {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const addressBookId = useAddressBookId()
  const isEnabled = !!authorizationHeader && isLoggedIn && !!shopperId && !!addressBookId
  const { locale } = useLocalization()

  const mutationResult = useMutation(({ request }: { request: AddressUploadRequest }) => {
    if (!!authorizationHeader && isLoggedIn && !!shopperId && !!addressBookId)
      return upsertAddress({ authorizationHeader, shopperId, addressBookId, request, locale })
    return failedApiPromiseForIncompleteArguments()
  })
  return useMemo(() => ({ isEnabled, mutationResult }), [isEnabled, mutationResult])
}

// Mailing List Related
export function useMailingListAll() {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const addressBookId = useAddressBookId()
  const { locale } = useLocalization()

  return useQuery(
    [queryKey.allMailingList, shopperId, addressBookId],
    async ({ signal }) => {
      if (authorizationHeader && isLoggedIn && shopperId && addressBookId) {
        return fetchMailingLists({ authorizationHeader, shopperId, addressBookId, signal, locale })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!authorizationHeader && !!isLoggedIn && !!shopperId && !!addressBookId,
      retry: 0,
    },
  )
}

export function useMailingListForId(mailingListId?: string | null) {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()

  return useQuery(
    [queryKey.mailingListForId, shopperId, mailingListId],
    async ({ signal }) => {
      if (authorizationHeader && isLoggedIn && shopperId && mailingListId) {
        return fetchMailingListById({ authorizationHeader, shopperId, mailingListId, signal })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!authorizationHeader && !!isLoggedIn && !!shopperId && !!mailingListId,
    },
  )
}

export function useMailingListCreate() {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()
  const requestId = useRequestIdQueryParam()

  const queryClient = useQueryClient()

  const isEnabled = !!authorizationHeader && isLoggedIn && !!shopperId && !!requestId

  const mutationResult = useMutation(
    ({ mailingListName }: { mailingListName?: string | null }) => {
      if (!!authorizationHeader && isLoggedIn && !!shopperId && !!requestId && mailingListName)
        return createMailingList({
          authorizationHeader,
          shopperId,
          requestId,
          mailingListName,
        })
      return failedApiPromiseForIncompleteArguments()
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(queryKey.allMailingList)
        await queryClient.invalidateQueries(queryKey.inProgressAddressList)
      },
    },
  )

  return useMemo(() => ({ isEnabled, mutationResult }), [isEnabled, mutationResult])
}

export function useMailingListUpdate(mailingListId?: string | null) {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()

  const queryClient = useQueryClient()

  const isEnabled = !!authorizationHeader && isLoggedIn && !!shopperId && !!mailingListId

  const mutationResult = useMutation(
    ({ request }: { request: MailingListUpdateRequest }) => {
      if (!!authorizationHeader && isLoggedIn && !!shopperId && !!mailingListId && !!request)
        return updateMailingList({
          authorizationHeader,
          shopperId,
          mailingListId,
          request,
        })
      return failedApiPromiseForIncompleteArguments()
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([queryKey.mailingListForId, shopperId, mailingListId])
        newRelicAddPageAction('Updated mailing list', { shopperId, mailingListId })
      },
    },
  )

  return useMemo(() => ({ isEnabled, mutationResult }), [isEnabled, mutationResult])
}

export function useDownloadMailingList(mailingListId?: string) {
  const authorizationHeader = useAuthorizationHeader()
  const shopperId = useShopperId()
  const { locale } = useLocalization()

  return useQuery(
    [queryKey.downloadMailingList, shopperId, mailingListId],
    async ({ signal }) => {
      if (authorizationHeader && shopperId && mailingListId) {
        return downloadMailingList({ authorizationHeader, locale, shopperId, mailingListId, signal })
      }
      return failedApiPromiseForIncompleteArguments()
    },
    {
      enabled: !!authorizationHeader && !!shopperId && !!mailingListId,
    },
  )
}

export function useValidateMailingListPostcard(request: MailingListCompatibilityRequest) {
  const authorizationHeader = useAuthorizationHeader()
  const shopperId = useShopperId()

  return useQuery(
    [queryKey.downloadMailingList, shopperId],
    async ({ signal }) => {
      if (authorizationHeader && shopperId && (request.mailingListId || request.requestId)) {
        return validateMailingListPostcard({
          authorizationHeader,
          shopperId,
          mailingListId: request.mailingListId,
          requestId: request.requestId,
          signal,
        })
      }
    },
    {
      enabled: !!authorizationHeader && !!shopperId,
    },
  )
}

export function useValidateMailingListPostcardMutation() {
  const authorizationHeader = useAuthorizationHeader()
  const isLoggedIn = useIsSignedIn()
  const shopperId = useShopperId()

  const isEnabled = !!authorizationHeader && !!shopperId

  const mutation = useMutation((request: MailingListCompatibilityRequest) => {
    if (!!authorizationHeader && isLoggedIn && !!shopperId && !!request)
      return validateMailingListPostcard({
        authorizationHeader,
        shopperId,
        mailingListId: request.mailingListId,
        requestId: request.requestId,
      })
    return failedApiPromiseForIncompleteArguments()
  })

  return useMemo(() => ({ isEnabled, mutationResult: mutation }), [isEnabled, mutation])
}
