import { Box, FlexBox, Hidden, Typography, useScreenClass } from '@vp/swan'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { usePrivacyPageURL } from 'lib/site-links'
import { useEventTracking } from 'lib/telemetry'
import { PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType, TrackingNavigationType } from 'lib/telemetry/tracking.types'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import uploadSecure from './secure.svg'
import classes from './upload-footer.module.scss'

const alignByScreenClass: Record<string, { textAlign: 'left' | 'center' }> = {
  xs: { textAlign: 'left' },
  sm: { textAlign: 'left' },
  md: { textAlign: 'center' },
  lg: { textAlign: 'center' },
  xl: { textAlign: 'center' },
}

export const UploadFooter: FC<{
  className?: string
}> = ({ className = '' }) => {
  const isPostcard = usePostcardPRDCheck()
  const privacyPolicyPath = usePrivacyPageURL()
  const fireTracking = useEventTracking()
  const screenClass = useScreenClass()
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)

  const onSeePrivacy = () => {
    const sourcePage = typeof window !== 'undefined' ? window.location.href : null
    const destinationPage = privacyPolicyPath
    const trackingData = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: TrackingLabelType.PRIVACY_LABEL,
      eventDetail: `${sourcePage};${destinationPage};Content;${TrackingNavigationType.PRIVACY_NAVIGATION}`,
      navigationDetail: TrackingNavigationType.PRIVACY_NAVIGATION,
      pageName: trackingProductPageName,
    }

    fireTracking(TrackingEventType.NAVIGATION_CLICKED, trackingData)
  }

  const intl = useIntl()
  const message = isPostcard
    ? intl.formatMessage({
        defaultMessage: "VistaPrint stores your mailing lists securely and won't use them for marketing purposes.",
        description: 'Privacy and cookie policy message',
      })
    : intl.formatMessage({
        defaultMessage: 'VistaPrint securely stores your address list. We never collect or sell this information. For more details, please see our',
      })
  const buttonMessage = intl.formatMessage({ defaultMessage: 'Privacy & Cookie Policy.' })

  return (
    <Box px={2} className={`${className} ${classes.footer}`}>
      <FlexBox justifyContent="center" alignItems="start">
        <Hidden xs sm mr={3}>
          <img src={uploadSecure} alt="" />
        </Hidden>
        <Typography fontSize={'small'} {...alignByScreenClass[screenClass]} textColor="subtle">
          {message}{' '}
          <a
            className={classes.privacyLink}
            href={privacyPolicyPath}
            target="_blank"
            rel="noopener noreferrer"
            data-section="Mailing List Page:Text Link"
            data-position="0"
            data-translation="See Privacy & Cookie Policy"
            onClick={onSeePrivacy}
          >
            {buttonMessage}
          </a>
        </Typography>
      </FlexBox>
    </Box>
  )
}
