import { PageProps } from 'gatsby'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { PagePattern } from 'lib/page-pattern'
import { ProductInfoProvider } from 'lib/product-info'
import { PageName } from 'lib/telemetry/tracking.types'
import { FC, memo } from 'react'
import { PageContextType } from 'types/application.types'
import { UploadPage } from './upload.page'
const MemoizedPage = memo(UploadPage)
const UploadTemplate: FC<PageProps<unknown, PageContextType>> = ({ pageContext, location }) => {
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)

  return (
    <PagePattern
      enforceSignIn={false}
      enforceSignInForWork
      search={location.search}
      i18nMessages={pageContext.messages}
      localeCulture={pageContext.localeCulture}
      pageName={trackingProductPageName}
    >
      <ProductInfoProvider>
        <MemoizedPage />
      </ProductInfoProvider>
    </PagePattern>
  )
}

export default UploadTemplate
