import { FullPageLoader } from 'components/loader/loader.component'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { useByPassCheck } from 'contexts/query-param.context'
import { useMsgSeoDescriptionUploadPage, useMsgSeoTitleUploadPage } from 'lib/intl/msg-seo-info.hooks'
import { useWorkDetails } from 'lib/work-entity'
import { FC } from 'react'
import { UploadContainer } from '../components/upload-container/upload-container.component'
import { UploadSampleContextProvider } from '../contexts/upload-sample.context'
import { UploadStatusContextProvider } from '../contexts/upload-status.context'

export const UploadPage: FC<unknown> = () => {
  const seoTitleUploadPage = useMsgSeoTitleUploadPage()
  const seoDescriptionUploadPage = useMsgSeoDescriptionUploadPage()
  const byPassCheck = useByPassCheck()
  const { data: workDetails } = useWorkDetails()

  return (
    <>
      <PageSeoTitle content={seoTitleUploadPage} />
      <PageSeoDescription content={seoDescriptionUploadPage} />
      <UploadSampleContextProvider>
        <UploadStatusContextProvider>{workDetails?.workId || byPassCheck ? <UploadContainer /> : <FullPageLoader />}</UploadStatusContextProvider>
      </UploadSampleContextProvider>
    </>
  )
}
