import {
  Box,
  Button,
  Card,
  Column,
  GridContainer,
  H4,
  Icon,
  List,
  ListItem,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogNav,
  ModalDialogTitle,
  Row,
  Typography,
} from '@vp/swan'
import classNames from 'classnames'
import { useLocalization } from 'hooks/use-localization'
import { useMsgAlternateTextMailingList } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgMailingListExampleLabel } from 'lib/intl/msg-mailing-list-actions.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import Markdown from 'markdown-to-jsx'
import { useUploadSampleContext } from 'modules/upload/contexts/upload-sample.context'
import { useDownloadTemplate } from 'modules/upload/hooks/useDownloadTemplate'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import requiredMLFormatEnUs from './requiredMLFormatEnUS.svg'
import requiredMLFormatEsUs from './requiredMLFormatEsUs.svg'
import classes from './upload-sample.module.scss'

export const UploadSample: FC<unknown> = () => {
  const { openSample, setOpenSample } = useUploadSampleContext()
  const isPostcard = usePostcardPRDCheck()
  const mailingListExampleLabel = useMsgMailingListExampleLabel()
  const alternateTextMailingList = useMsgAlternateTextMailingList()
  const zoomInSample = () => setOpenSample(true)
  const modalCloseLabel = useMsgModalClose()
  const [isFirstRender, setIsFirstRender] = useState(true)

  const { locale } = useLocalization()
  const requiredMlFormatRendered = useMemo(() => (locale === 'es-US' ? requiredMLFormatEsUs : requiredMLFormatEnUs), [locale])

  const intl = useIntl()
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [isEndOfScroll, setIsEndOfScroll] = useState<boolean>(false)
  const zoomButtonReference = useRef<HTMLElement>(null)
  const downloadTemplate = useDownloadTemplate()

  const allColumnHeadersFormatMessage = intl.formatMessage({
    defaultMessage: 'Your list should follow this format, including every column header.',
  })
  const allColumnHeadersAreRequiredMessage = intl.formatMessage({
    defaultMessage: 'Remember, fill either **Recipient** or **Company**. The rest of the fields are required.',
  })
  const zoomButtonAriaLabel = intl.formatMessage({
    defaultMessage: 'View large image of the address upload template file',
    description: 'Visually hidden text for viewing large template image',
  })
  const alternativeUploadSampleContainer = intl.formatMessage({
    defaultMessage: 'Click to view the complete example of the mailing list required format and extra information',
    description: 'Alt message for the box that contains the image for the required mailing list format',
  })

  const onScrollImageHandler = () => {
    if (imageContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = imageContainerRef.current
      if (scrollLeft + clientWidth === scrollWidth) {
        setIsEndOfScroll(true)
        return
      }

      setIsEndOfScroll(false)
    }
  }

  useEffect(() => {
    if (!isFirstRender && openSample) {
      zoomButtonReference.current?.focus()
    } else {
      setIsFirstRender(false)
    }
    // Reason: we do not want to focus when isFirstRender changes to false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSample])

  return (
    <>
      <Box role="button" onClick={zoomInSample} className={classNames(classes.uploadSampleContainer)} alt={alternativeUploadSampleContainer}>
        <Card bordered backgroundColor="standard" className={classes.uploadSample}>
          {isPostcard ? (
            <H4 mb={{ lg: 6, md: 5, xs: 4 }}>
              <FormattedMessage defaultMessage="Required mailing list format" />
            </H4>
          ) : (
            <H4 mb={{ lg: 6, md: 5, xs: 4 }}>
              <FormattedMessage defaultMessage="Example address List" />
            </H4>
          )}

          <div className={isEndOfScroll ? classes.scrollContainerNoFade : classes.scrollContainer} onScroll={onScrollImageHandler} ref={imageContainerRef}>
            <img src={requiredMlFormatRendered} alt={alternateTextMailingList} />
          </div>
        </Card>
        <Button
          aria-label={zoomButtonAriaLabel}
          skin="primary"
          onClick={zoomInSample}
          className={classes.zoomBtn}
          buttonShape="round"
          ref={zoomButtonReference}
        >
          <Icon iconType="zoomIn" size={'32p'} />
        </Button>
      </Box>
      <ModalDialog isOpen={openSample} onRequestDismiss={() => setOpenSample(false)}>
        <ModalDialogContent aria-label={mailingListExampleLabel} style={{ maxWidth: '95%', minWidth: '50%' }}>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
          </ModalDialogNav>
          <ModalDialogTitle>
            <Typography fontSize="x2large" fontWeight={'bold'}>
              <FormattedMessage defaultMessage="Required mailing list format" />
            </Typography>
          </ModalDialogTitle>
          <ModalDialogBody>
            <Box className={classes.metaContent} mt={3}>
              <Box mb={{ xs: 2, md: 4, lg: 5 }} className={classes.subTextContainer}>
                <GridContainer>
                  <Row>
                    <Column span={8} spanXs={12} spanSm={12}>
                      {isPostcard ? (
                        <>
                          <List skin={'minimal'}>
                            <ListItem my={0} py={0}>
                              <Typography fontSize={'small'}>
                                <Markdown>{allColumnHeadersFormatMessage}</Markdown>
                              </Typography>
                            </ListItem>
                            <ListItem my={0} py={0}>
                              <Typography fontSize={'small'}>
                                <Markdown>{allColumnHeadersAreRequiredMessage}</Markdown>
                              </Typography>
                            </ListItem>
                          </List>
                        </>
                      ) : (
                        <Typography fontSize={'standard'} mb={5}>
                          <FormattedMessage defaultMessage="Please include information required by the U.S. Postal Service: recipient, company, address, city, state and Zip code." />
                        </Typography>
                      )}
                    </Column>
                  </Row>
                </GridContainer>
              </Box>
            </Box>
            <Box className={`${classes.uploadImgContainer} `}>
              <img className={classes.uploadImg} src={requiredMlFormatRendered} alt={alternateTextMailingList} />
            </Box>
            <Box mt={4}>
              <GridContainer>
                <Row>
                  <Column span={4} spanMd={8} spanSm={12}>
                    <Button
                      iconPosition="right"
                      data-section="Mailing List Page:Link Clicked"
                      data-position="1"
                      data-translation="Download template"
                      specialVariant="design-path"
                      onClick={downloadTemplate}
                      size="standard"
                      className={classes.downloadBtn}
                      mt={4}
                    >
                      <Icon className={classes.icon} iconType="download" />
                      <FormattedMessage defaultMessage="Download template" description="Button label to download template for the mailing list" />
                    </Button>
                  </Column>
                </Row>
              </GridContainer>
            </Box>
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
