export enum UploadPageStatus {
  INITIAL = 'INITIAL',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  ERROR_UPLOADING_FAILED = 'ERROR_UPLOADING_FAILED',
  ERROR_PROCESSING_FAILED = 'ERROR_PROCESSING_FAILED',
  ERROR_INVALID_FILE_TYPE = 'ERROR_INVALID_FILE_TYPE',
  ERROR_INVALID_FILE_NAME = 'ERROR_INVALID_FILE_NAME',
  ERROR_EMPTY_FILE = 'ERROR_EMPTY_FILE',
  ERROR_NO_MAILING_LIST = 'ERROR_NO_MAILING_LIST',
}
