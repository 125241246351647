import { useLocalization } from 'hooks/use-localization'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { getMailingListTemplateBuffer } from 'lib/address-list/address-book.api'
import { PageName, useEventTracking } from 'lib/telemetry'
import { TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useAuthorizationHeader } from 'lib/user'
import { useCallback } from 'react'
import { generateAndDownloadTheTemplateFrom, getMLTemplateFileNameFrom } from '../components/upload-sample/download-ml-template'

export function useDownloadTemplate() {
  const { locale } = useLocalization()
  const authorizationHeader = useAuthorizationHeader() as string
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)
  const fireTracking = useEventTracking()

  const downloadTemplate = useCallback(async () => {
    const trackingData = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: TrackingLabelType.DOWNLOAD_TEMPLATE,
      pageName: trackingProductPageName,
    }

    fireTracking(TrackingEventType.LINK_CLICKED, trackingData)

    const mlTemplateResponse = await getMailingListTemplateBuffer({ locale, authorizationHeader })
    const buffer = mlTemplateResponse.data
    const fileName = getMLTemplateFileNameFrom(mlTemplateResponse)
    generateAndDownloadTheTemplateFrom({ buffer, fileName })
  }, [trackingProductPageName, fireTracking, locale, authorizationHeader])

  return downloadTemplate
}
