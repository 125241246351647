import { List, ListItem } from '@vp/swan'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const MailingServicesInstructions: FC = () => {
  return (
    <List skin="standard" px={5} py={3}>
      <ListItem my={0} py={0}>
        <FormattedMessage defaultMessage="Use the required mailing list format." />
      </ListItem>
      <ListItem my={0} py={0}>
        <FormattedMessage defaultMessage="Column headers are required." />
      </ListItem>
      <ListItem my={0} py={0}>
        <FormattedMessage defaultMessage="Accepted file types: .xls .xlsx .csv." />
      </ListItem>
    </List>
  )
}
